<template>
	<div class="login">
		<div class="login-weaper">
			<div class="login-left">
				<div class="login-time">{{ time.txt }}</div>
				<div class="login-left-box">
					<div>
						<div class="logo"><img :src="logo" /></div>
						<!-- <h2 class="title">后台管理系统</h2> -->
						<div class="msg">
							<div class="msg-author">
								<span>{{ quotations.name }}</span>
								<span>{{ quotations.comeFrom }}</span>
							</div>
							<div class="msg-txt">{{ quotations.content }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="login-right">
				<div class="login-main">
					<div class="login-title flex justify-content-center">
						<div class="text-lg text-black text-bold cursor" :class="{ 'border-bottom': isShow == true }"
							@click="isShow = true">手机号码登录</div>
						<div class="text-lg margin-left-50 cursor" :class="{ 'border-bottom': isShow == false }"
							@click="isShow = false">账号登录</div>
					</div>
					<el-form class="el-form login-form" :model="ruleForm" :rules="rules" ref="ruleForm" v-show="isShow">
						<el-form-item style="margin-left: 0px" prop="userName">
							<el-input type="text" placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone"
								v-model="ruleForm.userName" clearable autocomplete="off"
								@keyup.enter.native.stop="submitForm('ruleForm')" ref="userName"></el-input>
						</el-form-item>
						<el-form-item style="margin-left: 0px" prop="password">
							<el-input :type="isView ? 'type' : 'password'" placeholder="请输入密码"
								prefix-icon="el-icon-lock" v-model="ruleForm.password" clearable autocomplete="off"
								@keyup.enter.native.stop="submitForm('ruleForm')" ref="password">
								<i slot="suffix"
									:class="[isView ? 'el-icon-chat-dot-square' : 'el-icon-chat-square', 'el-input__icon']"
									@click="isView = !isView"></i>
							</el-input>
						</el-form-item>
						<div class="login-menu flex justify-content-between">
							<span class="cursor">手机号码重置密码</span>
							<span class="cursor">如何登录</span>
						</div>
						<el-form-item style="margin: 40px 0px 0">
							<el-button type="primary" class="login-submit" @click="submitForm('ruleForm')"
								:loading="submit.loading">
								<span>{{ submit.loadingTxt }}</span>
							</el-button>
						</el-form-item>
					</el-form>

					<el-form class="el-form login-form" :model="ruleForm" :rules="rules" ref="ruleForm"
						v-show="isShow == false">
						<el-form-item style="margin-left: 0px;display: none;" prop="schoolId">
							<el-input type="text" placeholder="请输入校区" prefix-icon="el-icon-house"
								v-model="ruleForm.schoolId" clearable autocomplete="off" ref="schoolId"></el-input>
						</el-form-item>
						<el-form-item style="margin-left: 0px" prop="userName">
							<el-input @keyup.native.enter="submitForm()" type="text" placeholder="请输入用户名" prefix-icon="el-icon-user"
								v-model="ruleForm.userName" clearable autocomplete="off" ref="userName"></el-input>
						</el-form-item>
						<el-form-item style="margin-left: 0px" prop="password">
							<el-input  @keyup.native.enter="submitForm()" :type="isView ? 'type' : 'password'" placeholder="请输入密码"
								prefix-icon="el-icon-lock" v-model="ruleForm.password" clearable autocomplete="off"
								ref="password">
								<i slot="suffix"
									:class="[isView ? 'el-icon-chat-dot-square' : 'el-icon-chat-square', 'el-input__icon']"
									@click="isView = !isView"></i>
							</el-input>
						</el-form-item>
						<div class="login-menu flex justify-content-between">
							<span class="cursor">忘记密码?</span>
							<span class="cursor">忘记后缀?</span>
						</div>
						<el-form-item style="margin: 40px 0px 0">
							<el-button type="primary" class="login-submit" @click="submitForm()"
								:loading="submit.loading">
								<span>{{ submit.loadingTxt }}</span>
							</el-button>
						</el-form-item>
					</el-form>
					<div class="login-menu"><span class="cursor">保存到桌面登录</span></div>
				</div>
			</div>
		</div>
		<div class="vue-particles">
			<vue-particles color="#dedede" :particleOpacity="0.7" :particlesNumber="80" shapeType="circle"
				:particleSize="4" linesColor="#dedede" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4"
				:linesDistance="150" :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true"
				clickMode="push" style="height: 100%"></vue-particles>
		</div>
	</div>
</template>

<script>
	import {
		formatDate
	} from '@/utils/formatTime';
	export default {
		name: 'login',
		data() {
			return {
				quotations: {},
				logo: require('@/assets/images/logo/logo.png'),
				isView: false,
				submit: {
					loading: false,
					loadingTxt: '登录'
				},
				ruleForm: {
					schoolId: 1,
					userName: '',
					password: '',
					code: ''
				},
				rules: {
					schoolId: [{
						required: true,
						message: '请输入校区ID',
						trigger: 'blur'
					}, {
						min: 0,
						max: 6,
						message: '长度在 1 到 5 个字符',
						trigger: 'blur'
					}],
					userName: [{
						required: true,
						message: '请输入登录名',
						trigger: 'blur'
					}, {
						min: 0,
						max: 30,
						message: '长度在 0 到 30 个字符',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, {
						min: 0,
						max: 10,
						message: '长度在 0 到 10 个字符',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}, {
						min: 3,
						max: 5,
						message: '长度在 3 到 5 个字符',
						trigger: 'blur'
					}]
				},
				time: {
					txt: '',
					fun: null
				},
				loginCode: null,
				isShow: false
			};
		},
		computed: {},
		created() {
			this.initTime();
		},
		destroyed() {
			clearInterval(this.time.fun);
		},
		mounted() {
			this.init();
		},
		methods: {
			// 初始化 `用户名input` 获取焦点，初始化 `验证码`
			init() {
				this.$refs['schoolId'].focus();
				this.onLoginCodeClick();
			},
			// 初始化左上角时间显示
			initTime() {
				this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM:SS WWW QQQQ');
				this.time.fun = setInterval(() => {
					this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM:SS WWW QQQQ');
				}, 1000);
			},
			// 初始化 `验证码`
			onLoginCodeClick() {
				this.loginCode = Math.random()
					.toString(36)
					.substr(-4);
			},
			// 登录按钮点击
			submitForm() {
        this.submit.loading = true
				this.API.login({
					school_id: this.ruleForm.schoolId,
					username: this.ruleForm.userName,
					password: this.ruleForm.password
				}).then(res => {
					if (res.code === 200) {
						let _this = this;
						_this.$router.push({
							name: 'Dashboard'
						});
						let token = res.token;
						let userInfo = res.data;
						_this.$store.commit('TOKEN', token);
						_this.$store.commit('USER_INFO', userInfo);
					} else {
						this.$message(res.message);
            
					}
				}).finally(() => {
          this.submit.loading = false
        })
			},
			// 获取 `input` 焦点函数
			inputFocus(type) {
				switch (Number.parseInt(type)) {
					case 0:
						this.inputFocusRefs('userName'); // 用户名
						break;
					case 1:
						this.inputFocusRefs('password'); // 密码
						break;
					case 2:
						this.inputFocusRefs('code'); // 验证码
						break;
				}
			},
			// 获取 `input` 焦点与默认选中文字
			inputFocusRefs(resfName) {
				this.$refs[resfName].focus();
				this.$refs[resfName].select();
			}
		}
	};
</script>

<style scoped lang="scss">
	.border-bottom::after {
		width: 50%;
		display: block;
		margin: 0 auto;
		height: 1px;
		padding-bottom: 5px;
		border-bottom: 4px solid #0081ff;
		content: ' ';
		border-radius: 2px;
	}
	.login {
		height: 100%;
		width: 100%;
		overflow: hidden;
		display: flex;
		position: relative;

		.vue-particles {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: radial-gradient(ellipse at top left, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
		}

		.login-weaper {
			margin: auto;
			height: 500px;
			display: flex;
			box-sizing: border-box;
			position: relative;
			z-index: 1;
			border: none;
			box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

			.login-left {
				width: 500px;
				padding: 20px;
				font-size: 16px;
				color: #fff;
				position: relative;
				background-color: rgba(0, 153, 255, 0.6);
				display: flex;
				flex-direction: column;

				.login-time {
					width: 100%;
					color: #fff;
					opacity: 0.9;
					font-size: 14px;
					overflow: hidden;
				}

				.login-left-box {
					flex: 1;
					overflow: hidden;
					position: relative;
					z-index: 1;
					display: flex;
					align-items: center;
					padding: 80px 45px;

					.logo {
						height: 45px;
						display: inline-block;
						font-size: 35px;

						img {
							width: 100%;
						}
					}

					.title {
						color: #fff;
						font-weight: 300;
						letter-spacing: 2px;
						font-size: 16px;
					}

					.msg {
						color: #fff;
						font-size: 13px;
						margin-top: 35px;

						.msg-author {
							opacity: 0.6;

							span:last-of-type {
								margin-left: 15px;
							}
						}

						.msg-txt {
							margin-top: 15px;
							line-height: 22px;
						}
					}
				}
			}

			.login-right {
				width: 500px;
				padding: 20px;
				position: relative;
				align-items: center;
				display: flex;
				background-color: rgba(255, 255, 255, 1);

				.login-main {
					margin: 0 auto;
					width: 70%;

					.login-title {
						color: #333;
						margin-bottom: 40px;
						font-weight: 500;
						font-size: 22px;
						text-align: center;
					}

					.login-form {
						margin: 10px 0;

						i {
							color: #333;
						}

						.el-form-item {
							margin-bottom: 20px !important;

							.login-code {
								display: flex;
								align-items: center;
								justify-content: space-around;
								margin: 0 0 0 10px;

								.login-code-img {
									margin-top: 2px;
									width: 100px;
									height: 38px;
									background-color: #fdfdfd;
									border: 1px solid #dcdfe6;
									color: #333;
									font-size: 14px;
									font-weight: 700;
									letter-spacing: 5px;
									line-height: 38px;
									text-indent: 5px;
									text-align: center;
									cursor: pointer;
									transition: all ease 0.2s;

									&:hover {
										border-color: #c0c4cc;
										transition: all ease 0.2s;
									}
								}
							}

							.login-submit {
								width: 100%;
								height: 45px;
								letter-spacing: 2px;
								font-weight: 300;
							}
						}
					}

					.login-menu {
						margin-top: 30px;
						width: 100%;
						text-align: left;

						span {
							color: #999;
							font-size: 12px;
							margin: 0 8px;
							text-decoration: none;

							&:hover {
								color: #1e9fff;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1000px) {
			.login-left {
				display: none !important;
			}
		}
	}
</style>
